import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Form from "./pages/form";
import Upload from "./pages/upload";
import Consent from "./pages/consent";

function App() {
  const [stage, setStage] = useState("form");
  const [nompaciente, setNompaciente] = useState("");
  const [docpaciente, setDocpaciente] = useState("");
  const [nomresponsable, setNomresponsable] = useState("");
  const [docresponsable, setDocresponsable] = useState("");
  const [servicio, setServicio] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [horario, setHorario] = useState("");
  const [asistido, setAsistido] = useState("");
  const [orden, setOrden] = useState([]);
  const [nombreorden, setNombreorden] = useState("");
  const [habilitador, setHabilitador] = useState(true);
  const [firma, setFirma] = useState("");
  const [celular, setCelular] = useState("");
  const [enabler, setEnabler] = useState(false);

  useEffect(() => {
    if ((proveedor == "Ecopetrol (contrato en renovaciòn - no se aceptan solicitudes)")) {
      setEnabler(true)
    } else {
      setEnabler(false)
    }
  }, [proveedor]);

  const Next = () => {
    console.log(enabler)
    switch (stage) {
      case "form":
        if (
          nompaciente === "" ||
          docpaciente === "" ||
          servicio === "" ||
          proveedor === "" ||
          horario === "" ||
          asistido === "" ||
          celular === ""
        ) {
          alert("Ingrese todos los datos");
        } else {
          setStage("upload");
        }

        break;
      case "upload":
        if (asistido === "Siguiente") {
          setStage("consent");
        } else if (asistido === "Enviar") {
          Send();
        }

        break;
    }
  };

  const Previous = () => {
    switch (stage) {
      case "upload":
        setStage("form");
        break;
      case "consent":
        setStage("upload");
        break;
    }
  };

  const UploadFile = async () => {
    const data = new FormData();
    data.append("userfile", orden);
    axios
      .post(
        "https://registro-usuarios.riie.com.co/server/upload_order.php",
        data
      )
      .then((response) => {
        alert("Archivo subido exitosamente");
        setNombreorden(response.data);
        if (response.date === "") {
          setHabilitador(true);
        } else {
          setHabilitador(false);
        }
      });
  };

  const Send = async () => {
    if (asistido === "Siguiente") {
      var datos = {
        NombrePaciente: nompaciente,
        DocPaciente: docpaciente,
        NombreResponsable: nomresponsable,
        DocResponsable: docresponsable,
        TerapiaSolicitada: servicio,
        Proveedor: proveedor,
        RangoHorario: horario,
        Firma: firma,
        Celular: celular,
        LinkOrden:
          "https://registro-usuarios.riie.com.co/server/ordenes/" + nombreorden,
      };
      if (firma === "" || nomresponsable === "" || docresponsable === "") {
        alert("Ingrese su firma");
      } else {
        try {
          await axios.post(
            "https://registro-usuarios.riie.com.co/server/registro_usuarios_nuevos.php",
            datos
          );
          alert("Envio exitoso");
        } catch (err) {
          console.log(err);
        }
        window.location.replace(
          "https://www.registro-usuarios.riie.com.co/",
          "_blank"
        );
      }
    } else if (asistido === "Enviar") {
      var datos = {
        NombrePaciente: nompaciente,
        DocPaciente: docpaciente,
        NombreResponsable: "NO APLICA",
        DocResponsable: "NO APLICA",
        TerapiaSolicitada: servicio,
        Proveedor: proveedor,
        RangoHorario: horario,
        Firma: "NO APLICA",
        Celular: celular,
        LinkOrden:
          "https://registro-usuarios.riie.com.co/server/ordenes/" + nombreorden,
      };
      try {
        await axios.post(
          "https://registro-usuarios.riie.com.co/server/registro_usuarios_nuevos.php",
          datos
        );
        alert("Envio exitoso");
      } catch (err) {
        console.log(err);
      }
      window.location.replace(
        "https://www.registro-usuarios.riie.com.co/",
        "_blank"
      );
    }
  };

  switch (stage) {
    case "form":
      return (
        <>
          <Form
            Next={Next}
            setNompaciente={setNompaciente}
            setDocpaciente={setDocpaciente}
            setServicio={setServicio}
            setProveedor={setProveedor}
            setHorario={setHorario}
            setAsistido={setAsistido}
            setCelular={setCelular}
            enabler={enabler}
          />
        </>
      );
      break;
    case "upload":
      return (
        <>
          <Upload
            Next={Next}
            Previous={Previous}
            habilitador={habilitador}
            setOrden={setOrden}
            UploadFile={UploadFile}
            asistido={asistido}
          />
        </>
      );
      break;
    case "consent":
      return (
        <>
          <Consent
            Previous={Previous}
            setFirma={setFirma}
            Send={Send}
            nompaciente={nompaciente}
            docpaciente={docpaciente}
            setNomresponsable={setNomresponsable}
            setDocresponsable={setDocresponsable}
          />
        </>
      );
      break;
    default:
      break;
  }
}

export default App;
