import React, { useEffect, useState } from "react";

const Form = (props) => {
  const [terapa, setTerapA] = useState("");
  const [terapb, setTerapB] = useState("");
  const [terapc, setTerapC] = useState("");
  const [terapd, setTerapD] = useState("");

  useEffect(() => {
    props.setServicio(terapa + " " + terapb + " " + terapc + " " + terapd);
  
  }, [terapa, terapb, terapc, terapd]);
  return (
    <div className="container">
      <h1>Formulario de registro de ordenes RIIE</h1>
      <label>
        ¿Quien esta llenando el formulario?{" "}
        <select onChange={(e) => props.setAsistido(e.target.value)}>
          <option hidden>Por favor seleccione una opciòn</option>
          <option value="Siguiente">Paciente</option>
          <option value="Enviar">Asistido por RIIE</option>
        </select>
      </label>

      <label>
        Nombre completo del paciente:{" "}
        <input
          type="text"
          placeholder="Ingrese el nombre del paciente"
          onChange={(e) => props.setNompaciente(e.target.value)}
        />
      </label>
      <label>
        Documento del paciente:{" "}
        <input
          type="text"
          placeholder="Ingrese el documento del paciente"
          onChange={(e) => props.setDocpaciente(e.target.value)}
        />
      </label>
      <label>
        Nùmero de telefono del paciente{" "}
        <input
          placeholder="Ingrese su nùmero de telefono"
          onChange={(e) => props.setCelular(e.target.value)}
        />
      </label>
      <label>Terapias solicitadas:</label>
      <label>
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked === true) {
              setTerapA("Atención (Visita) Domiciliaria Terapia Física");
            } else {
              setTerapA("");
            }
          }}
        />{" "}
        Atención (Visita) Domiciliaria Terapia Física
      </label>
      <label>
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked === true) {
              setTerapB("Atención (Visita) Domiciliaria Terapia Ocupacional");
            } else {
              setTerapB("");
            }
          }}
        />{" "}
        Atención (Visita) Domiciliaria Terapia Ocupacional
      </label>
      <label>
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked === true) {
              setTerapC("Atención (Visita) Domiciliaria Fonoaudiología");
            } else {
              setTerapC("");
            }
          }}
        />{" "}
        Atención (Visita) Domiciliaria Fonoaudiología
      </label>
      <label>
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked === true) {
              setTerapD("Atención (Visita) Domiciliaria Por Psicología");
            } else {
              setTerapD("");
            }
          }}
        />{" "}
        Atención (Visita) Domiciliaria Por Psicología
      </label>
      <label>
        Proveedor de cita:{" "}
        <select onChange={(e) => props.setProveedor(e.target.value)}>
          <option hidden>Por favor seleccione su proveedor de cita</option>
          <option>Ecopetrol (contrato en renovaciòn - no se aceptan solicitudes)</option>
          <option>Sura</option>
          <option>Seguros Bolivar</option>
          <option>Allianz</option>
          <option>Mapfre</option>
          <option>AXA Colpatria</option>
          <option>Particular</option>
          <option>Medplus</option>
        </select>
      </label>
      <label>
        Rango disponible para programar las terapias:{" "}
        <select onChange={(e) => props.setHorario(e.target.value)}>
          <option hidden>Seleccione su rango para programar las citas</option>
          <option>AM</option>
          <option>PM</option>
          <option>Cualquier disponibilidad ofrecida</option>
        </select>
      </label>
      <button onClick={props.Next} disabled={props.enabler}>Siguiente</button>
    </div>
  );
};

export default Form;
